/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_pNK1jCPXN",
    "aws_user_pools_web_client_id": "12ki1ppqlaeikvevulqbhu2men",
//    "aws_user_pools_id": "ca-central-1_uCeK68EkD",
//    "aws_user_pools_web_client_id": "2ob5pujfnsiv7jlt2tgjtmt887",

    "oauth": {}
};


export default awsmobile;
